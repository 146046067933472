import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

function CalcHighestAuthorityLevel () {
  const dispatch = useDispatch();

  // from store
  const signedInUser = useSelector(state => state.signedInUser);
  const modIdDict = useSelector(state => state.modIdDict);
  const modIdsLoading = useSelector(state => state.modIdsLoading);
  const adminIdDict = useSelector(state => state.adminIdDict);
  const adminIdsLoading = useSelector(state => state.adminIdsLoading);
  const ownerIdDict = useSelector(state => state.ownerIdDict);
  const ownerIdsLoading = useSelector(state => state.ownerIdsLoading);

  useEffect (() => {
    if (!signedInUser) {
      // wait for signedInUser
      return;
    }

    if (modIdsLoading || adminIdsLoading || ownerIdsLoading) {
      // wait for ids to finish loading
      return;
    }

    // calculate highest authority level
    let highestAuthorityLevel = "none";
    //if (!modIdsLoading && !adminIdsLoading && !ownerIdsLoading && signedInUser) {
    // if (signedInUser) {
    // all lists loaded.
    if (modIdDict && modIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "mod";
    }
    if (adminIdDict && adminIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "admin";
    }
    if (ownerIdDict && ownerIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "owner";
    }
    // }

    // save result in store
    dispatch({
      type: "setHighestAuthorityLevel",
      value: highestAuthorityLevel,
    });
  }, [signedInUser, modIdDict, adminIdDict, ownerIdDict, modIdsLoading,
      adminIdsLoading, ownerIdsLoading]);




  // don't show anything
  return <></>;
}

export default CalcHighestAuthorityLevel;
