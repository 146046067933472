import { createStore } from 'redux'

/**
 * This is a reducer - a function that takes a current state value and an
 * action object describing "what happened", and returns a new state value.
 * A reducer's function signature is: (state, action) => newState
 *
 * The Redux state should contain only plain JS objects, arrays, and primitives.
 * The root state value is usually an object.  It's important that you should
 * not mutate the state object, but return a new object if the state changes.
 *
 * You can use any conditional logic you want in a reducer. In this example,
 * we use a switch statement, but it's not required.
 */
function reducer(state = {
  // initial state
  value: 0,
  // if no user is signed in, this will be null
  signedInUser: null,
  signedInUserPrivate: null,
  pendingSignedInUser: null,
  signedInUserLoading: true,
  signedInUserUpdater: 0,

  alertText: "",
  alertVariant: "dark",
  alertDelay: 0,
  alertVisible: false,

  brandList: null,

  adminIdDict: null,
  adminIdsLoading: true,
  modIdDict: null,
  modIdsLoading: true,
  ownerIdDict: null,
  ownerIdsLoading: true,
  sellerIdDict: null,
  sellerIdsLoading: true,
  highestAuthorityLevel: "none",
  highestAuthorityLevelLoading: true,

  itemPreapprovedUserIdDict: null,
  itemPreapprovedUserIdsLoading: true,
  postPreapprovedUserIdDict: null,
  postPreapprovedUserIdsLoading: true,

  usernameToFirebaseUid: null,
  firebaseUidToUsername: null,

  modalTitleText: "",
  modalBodyText: "",
  modalBodyElement: null,
  modalShowing: false,
  modalUseButtons: true,
  modalConfirmCallback: null,
  modalCancelCallback: null,
  modalConfirmText: "",
  modalCancelText: "",

  numItems: null,

  currencyConversionRates: {},

  recaptchaLoaded: false,

  pauseMode: {},
  pauseModeLoading: true,

  newSiteVersionAvailable: false, // a new site version is available, we will refresh soon

  autoApprove: {}, // new users with or without invite

  // automatically fill in message text when creating new item convo
  itemConvoAutoFill: {
    active: false,
    text: "",
    createdMs: 0,
  },

  pathname: "",
  loweredPathname: "",
  locationDataLoaded: false,

  // Filter modals
  filterModal: {
    // is the modal visible?
    visible: false,
    // should we be filtering something?
    filterActive: false,
    // what should we be filtering?
    filter: {},
    // what order should items be displayed?
    sortBy: null,
    // when apply button pressed
    // track this to do an action when button pressed
    applyPressedTimeMs: 0,
    clearPressedTimeMs: 0,
    resetPressedTimeMs: 0,
  },
  filterModalUpdatedTimeMs: 0,

  countriesDict: null,
  countriesDictLoading: true,

  homeQuickRestore: {
    active: false,
    sortBy: null,
    filter: {},
    filterActive: false,
    itemIds: [],
    scrollPositionX: 0,
    scrollPositionY: 0,
    searchText: "",
    searching: false,
    favoritesActive: false,
    searchPage: "items",
    lastClickedId: null,
    lastClickedType: "",
    loaderStartAfter: null,
    itemSelector: null,
  },
  exploreQuickRestore: {
    active: false,
    sortBy: null,
    filter: {},
    filterActive: false,
    itemIds: [],
    scrollPositionX: 0,
    scrollPositionY: 0,
    searchText: "",
    searching: false,
    favoritesActive: false,
    explorePage: "items",
    searchPage: "items",
    lastClickedId: null,
    lastClickedType: "",
    loaderStartAfter: null,
    itemSelector: null,
  },
  userPageQuickRestore: {
    active: false,
    sortBy: null,
    filter: {},
    filterActive: false,
    itemIds: [],
    scrollPositionX: 0,
    scrollPositionY: 0,
    favoritesActive: false,
    page: "feed",
    layoutMode: "gridView",
    lastClickedId: null,
    lastClickedType: "",
    loaderStartAfter: null,
    itemSelector: null,
    user: {},
  },
}, action) {
  if (!action || !action.type) {
    console.error("action or action tyoe missing", action);
    return state;
  }

  if (typeof action.value === "undefined") {
    if (
      !["resetFilterModal", "resetFilterModalWithTrigger"]
                   .includes(action.type)
    ) {
      // this is not one of the types that does not need a value,
      // so this is an error, do nothing
      console.error("action value is undefined", action);
      return state;
    }
  }

  switch (action.type) {
    case 'setSignedInUser':
      return {
        ...state,
        signedInUser: action.value,
        signedInUserUpdater: state.signedInUserUpdater + 1,
      };
    case 'setSignedInUserPrivate':
      return {
        ...state,
        signedInUserPrivate: action.value,
        signedInUserUpdater: state.signedInUserUpdater + 1,
      };
    case 'setPendingSignedInUser':
      return {
        ...state,
        pendingSignedInUser: action.value,
        signedInUserUpdater: state.signedInUserUpdater + 1,
      };
    case 'setSignedInUserLoading':
      return {
        ...state,
        signedInUserLoading: action.value,
        signedInUserUpdater: state.signedInUserUpdater + 1,
      };

    case 'setAlert':
      // set alert with default delay
      return {
        ...state,
        alertText: action.value.text,
        alertVariant: action.value.variant,
        alertVisible: true,
        alertDelay: action.value.delay || 2000,
      };
    case 'setAlertWithDelayAndVisible':
      // set alert with custom delay and visible values
      return {
        ...state,
        alertText: action.value.text,
        alertVariant: action.value.variant,
        alertDelay: action.value.delay,
        alertVisible: action.value.visible,
      };

    case "setBrandList":
      return {...state, brandList: action.value};

    case "setAdminIdDict":
      return {...state, adminIdDict: action.value, adminIdsLoading: false};
    case "setAdminIdsLoading":
      return {...state, adminIdsLoading: action.value};

    case "setModIdDict":
      return {...state, modIdDict: action.value, modIdsLoading: false};
    case "setModIdsLoading":
      return {...state, modIdsLoading: action.value};

    case "setOwnerIdDict":
      return {...state, ownerIdDict: action.value, ownerIdsLoading: false};
    case "setOwnerIdsLoading":
      return {...state, ownerIdsLoading: action.value};

    case "setSellerIdDict":
      return {...state, sellerIdDict: action.value, sellerIdsLoading: false};
    case "setSellerIdsLoading":
      return {...state, sellerIdsLoading: action.value};

    case "setHighestAuthorityLevel":
      return {...state, highestAuthorityLevel: action.value, highestAuthorityLevelLoading: false};
    case "setHighestAuthorityLevelLoading":
      return {...state, highestAuthorityLevelLoading: action.value};

    case "setItemPreapprovedUserIdDict":
      return {...state, itemPreapprovedUserIdDict: action.value, itemPreapprovedUserIdsLoading: false};
    case "setItemPreapprovedUserIdsLoading":
      return {...state, itemPreapprovedUserIdsLoading: action.value};

    case "setPostPreapprovedUserIdDict":
      return {...state, postPreapprovedUserIdDict: action.value, postPreapprovedUserIdsLoading: false};
    case "setPostPreapprovedUserIdsLoading":
      return {...state, postPreapprovedUserIdsLoading: action.value};

    case "setUsernameToFirebaseUid":
      return {...state, usernameToFirebaseUid: action.value};
    case "setFirebaseUidToUsername":
      return {...state, firebaseUidToUsername: action.value};

    case "setNumItems":
      return {...state, numItems: action.value};

    case "setCurrencyConversionRates":
      return {...state, currencyConversionRates: action.value};

    case "setRecaptchaLoaded":
      return {...state, recaptchaLoaded: action.value};

    case "setPauseMode":
      return {...state, pauseMode: action.value};
    case "setPauseModeLoading":
      return {...state, pauseModeLoading: action.value};

    case "setItemConvoAutoFill":
      return {...state, itemConvoAutoFill: action.value};

    case "setNewSiteVersionAvailable":
      return {...state, newSiteVersionAvailable: action.value};

    case "setAutoApprove":
      return {...state, autoApprove: action.value};

    case "setModal":
      // create modal with title, body, and callbacks
      // console.log("setting modal data", action.value);
      let showing = action.value.showing;
      if (typeof showing === "undefined")
        showing = true;

      let useButtons = action.value.useButtons;
      if (typeof useButtons === "undefined")
        useButtons = true;

      let bodyText = action.value.bodyText;
      if (!action.value.bodyText) {
        // nothing provided for body text.
        // check if something provided for bodyElement
        if (action.value.bodyElement) {
          // use element instead and make text nothing
          bodyText = "";
        }
        else {
          // nothing provided for body element either
          // use default body text
          bodyText = "Are you sure?";
        }
      }

      return {
        ...state,
        modalTitleText: action.value.titleText || "Confirm Action",
        modalBodyText: bodyText,
        modalBodyElement: action.value.bodyElement || null,
        modalShowing: showing,
        modalUseButtons: useButtons,
        modalConfirmCallback: action.value.confirmCallback || null,
        modalCancelCallback: action.value.cancelCallback || null,
        modalConfirmText: action.value.confirmText || "Confirm",
        modalCancelText: action.value.cancelText || "Cancel",
      };

    case "setLocationData":
      return {
        ...state,
        locationDataLoaded: true,
        pathname: action.value.pathname,
        loweredPathname: action.value.loweredPathname,
      };

    // filter modals
    case "resetFilterModal":
      const tempFilterModal = state.filterModal || {};
      console.log("doing reset modal");
      return {
        ...state,
        filterModal: {
          visible: false,
          filterActive: false,
          filter: {},
          sortBy: "",

          // keep these values the same to avoid triggering any functions
          applyPressedTimeMs: tempFilterModal.applyPressedTimeMs || 0,
          clearPressedTimeMs: tempFilterModal.clearPressedTimeMs || 0,
          resetPressedTimeMs: tempFilterModal.resetPressedTimeMs || 0,
        },
        filterModalUpdatedTimeMs: Date.now(),
      };
    case "resetFilterModalWithTrigger":
      const filterModalData = state.filterModal || {};

      return {
        ...state,
        filterModal: {
          visible: false,
          filterActive: false,
          filter: {},
          sortBy: "",
          resetPressedTimeMs: Date.now(),

          // keep these values the same to avoid triggering any functions
          applyPressedTimeMs: filterModalData.applyPressedTimeMs || 0,
          clearPressedTimeMs: filterModalData.clearPressedTimeMs || 0,
        },
        filterModalUpdatedTimeMs: Date.now(),
      };
    case "setFilterModal":
      const forFilterModal = state.filterModal || {};
      console.log("received ", action.value);
      if (typeof action.value.visible === "boolean") {
        forFilterModal.visible = action.value.visible;
      }
      if (typeof action.value.filterActive === "boolean") {
        forFilterModal.filterActive = action.value.filterActive;
      }
      if (typeof action.value.sortBy !== "undefined") {
        forFilterModal.sortBy = action.value.sortBy;
      }
      if (typeof action.value.filter !== "undefined") {
        forFilterModal.filter = action.value.filter;
      }
      if (typeof action.value.applyPressedTimeMs === "number") {
        forFilterModal.applyPressedTimeMs = action.value.applyPressedTimeMs;
      }
      if (typeof action.value.clearPressedTimeMs === "number") {
        forFilterModal.clearPressedTimeMs = action.value.clearPressedTimeMs;
      }
      if (typeof action.value.resetPressedTimeMs === "number") {
        forFilterModal.resetPressedTimeMs = action.value.resetPressedTimeMs;
      }

      console.log("setting", forFilterModal);

      return {
        ...state,
        filterModal: forFilterModal,
        filterModalUpdatedTimeMs: Date.now(),
      };

    case "setCountriesDict":
        return {
          ...state,
          countriesDict: action.value,
          countriesDictLoading: false,
        }

    case "setCountriesLoading":
        return {
          ...state,
          countriesDictLoading: action.value,
        }

    case "setHomeQuickRestore":
        return {
          ...state,
          homeQuickRestore: action.value,
        }
    case "setExploreQuickRestore":
        return {
          ...state,
          exploreQuickRestore: action.value,
        }
    case "setUserPageQuickRestore":
        return {
          ...state,
          userPageQuickRestore: action.value,
        }

    default:
      console.error("default reached in store", action);
      return state;
  }
}

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
let store = createStore(reducer)

// You can use subscribe() to update the UI in response to state changes.
// Normally you'd use a view binding library (e.g. React Redux) rather than subscribe() directly.
// There may be additional use cases where it's helpful to subscribe as well.

// store.subscribe(() => console.log(store.getState()))

export default store;
