import Spinner from 'react-bootstrap/Spinner';

function Loading({type="large"}) {
  // type: small/large (default)

  if (type === "small") {
    return (
      <div className="loading">
        <Spinner animation="border" variant="light"  role="status"
          style={{
            width: "3rem",
            height: "3rem",
            borderWidth: "0.05em",
          }}
        >
          {
            // Loading...
          }
          <span style={{width: "1px", height: "1px", clip: "rect(0,0,0,0)", opacity: 0}}>
            Loading...
          </span>
        </Spinner>
      </div>
    );
  }


  return (
    <div className="loading" style={{paddingTop: "50px"}}>
      <Spinner animation="border" variant="light"  role="status"
        style={{
          width: "4rem",
          height: "4rem",
          borderWidth: "0.1em",
        }}
      >
        {
          // Loading...
        }
        <span style={{width: "1px", height: "1px", clip: "rect(0,0,0,0)", opacity: 0}}>
          Loading...
        </span>
      </Spinner>
    </div>
  );
}

export default Loading;
