// load from library
import { MdFavoriteBorder } from "react-icons/md";
import { MdFavorite } from "react-icons/md";
import { MdStar } from "react-icons/md";
import { MdStarHalf } from "react-icons/md";
import { MdStarBorder } from "react-icons/md";

// load svg from file
import {ReactComponent as InactiveSearchIcon} from "../images/svg/zoom_normal.svg";
import {ReactComponent as ActiveSearchIcon} from "../images/svg/zoom_white.svg";
import largeTitleLogo from "../images/svg/logo_and_word.svg";
import greyFilterIcon from  "../images/svg/filter_black.svg";
import whiteFilterIcon from  "../images/svg/filter_black.svg";
import {ReactComponent as InactiveFilterIcon} from  "../images/svg/filter_black.svg";

import {ReactComponent as InactiveBellIcon} from "../images/svg/bell_inactive.svg";
import {ReactComponent as ActiveBellIcon} from "../images/svg/bell_active.svg";
import {ReactComponent as InactiveFeedIcon} from "../images/svg/feed_normal.svg";
import {ReactComponent as ActiveFeedIcon} from "../images/svg/feed_white.svg";
import {ReactComponent as InactiveHomeIcon} from "../images/svg/home_normal.svg";
import {ReactComponent as ActiveHomeIcon} from "../images/svg/home_white.svg";
import {ReactComponent as InactiveMessagesIcon} from "../images/svg/message_inactive.svg";
import {ReactComponent as ActiveMessagesIcon} from "../images/svg/message_active.svg";
import {ReactComponent as InactiveAddIcon} from "../images/svg/add_normal.svg";
import {ReactComponent as ActiveAddIcon} from "../images/svg/add_white.svg";
import {ReactComponent as ActiveMenuIcon} from "../images/svg/menu_white.svg";
import {ReactComponent as EditIcon} from "../images/svg/pencil_black.svg";
import {ReactComponent as DeleteIcon} from "../images/svg/trash_red.svg";
import {ReactComponent as PhotoIcon} from "../images/svg/add_image.svg";
import {ReactComponent as commentIcon} from "../images/svg/comment_bubble.svg";
import {ReactComponent as thinHeartIcon} from "../images/svg/heart_thin.svg";
import {ReactComponent as thinHeartFilledIcon} from "../images/svg/heart_thin_filled.svg";
import {ReactComponent as sendIcon} from "../images/svg/send.svg";
import {ReactComponent as gridviewIcon} from "../images/svg/gridview.svg";
import {ReactComponent as listviewIcon} from "../images/svg/listview.svg";
import {ReactComponent as storeIcon} from "../images/svg/store.svg";
import {ReactComponent as copyIcon} from "../images/svg/copy.svg";

// load img from file
import greyHeartIcon from "../images/Icon_Grey_Heart.png";
import whiteHeartIcon from "../images/Icon_white_Heart.png";

import greyShoppingIcon from "../images/Icon_Grey_Shopping.png";
import whiteShoppingIcon from "../images/Icon_white_Shopping.png";
import greyExploreIcon from "../images/Icon_Grey_Explore.png";
import greyFeedIcon from "../images/Icon_Grey_Feed.png";
// import greyPlusIcon from "../images/Icon_Grey_Plus.png";
// import whitePlusIcon from "../images/Icon_white_Plus.png";
import greyStoreIcon from "../images/Icon_Grey_Store.png";
import whiteStoreIcon from "../images/Icon_white_Store.png";

import whiteCartIcon from "../images/Icon_white_Cart.png";

import whiteExploreIcon from "../images/Icon_white_Explore.png";

import addCircleIcon from "../images/Icon_white_Plus.png";
import xIcon from "../images/Button_Remove.png";
import leftIcon from "../images/Logo_left.png";
import rightIcon from "../images/Logo_right.png";






function IconLoader({
  name,
  active=false,
  className,
  id,
  onClick,
  size,
  style,
  alt,
}) {

  const type = nameToType[name] || "img";

  let tempAlt = name + " icon ";
  let tempClassName = " icon ";
  if (active) {
    tempClassName += " active ";
    tempAlt += " active ";
  }
  else {
    tempClassName += " inactive ";
    tempAlt += " inactive ";
  }
  tempClassName += " " +name+ "Icon ";
  tempClassName += " " + (className||"");

  if (alt) {
    tempAlt = alt;
  }


  if (typeof active !== "boolean") {
    console.error("non boolean sent in for active");
    return <></>;
  }

  if (type === "img") {
    const imgSrc = activeNameToIcon[active][name];
    return <img src={imgSrc} id={id} className={tempClassName} onClick={onClick}
                alt={tempAlt} size={size} style={style}/>;
  }
  else if (type === "svg" || type === "library") {
    const SVGComponent = activeNameToIcon[active][name];
    return <SVGComponent id={id} className={tempClassName} onClick={onClick}
                alt={tempAlt} size={size} style={style}/>;
  }

  return name;
}


// to use, call activeNameToIcon[iconActive][iconName]
const activeNameToIcon = {
  // Active
  true: {
    add: ActiveAddIcon,
    bell: ActiveBellIcon,
    delete: DeleteIcon,
    edit: EditIcon,
    feed: ActiveFeedIcon,
    filter: InactiveFilterIcon,
  	store: storeIcon,
  	copy: copyIcon,
    heart: MdFavorite,
  	send: sendIcon,
  	gridview: gridviewIcon,
  	listview: listviewIcon,

    home: ActiveHomeIcon,
    messages: ActiveMessagesIcon,
    search: ActiveSearchIcon,
    star: MdStar,
    starHalf: MdStarHalf,
    photo:PhotoIcon,
    comment:commentIcon,
  	//thin heart active and filled
  	thinHeart:thinHeartFilledIcon,
  	thinHeartFilled:thinHeartFilledIcon,

    addCircle: addCircleIcon,
    left: leftIcon,
    right: rightIcon,
    x: xIcon,
  }
};

activeNameToIcon[false] = {
  // Inactive same as true with some exceptions
  ...activeNameToIcon[true],
  add: InactiveAddIcon,
  bell: InactiveBellIcon,
  feed: InactiveFeedIcon,
  heart: MdFavoriteBorder,
  thinHeart: thinHeartIcon,
  home: InactiveHomeIcon,
  messages: InactiveMessagesIcon,
  search: InactiveSearchIcon,
  star: MdStarBorder,
}


const nameToType = {
  add: "svg",
  bell: "svg",
  delete: "svg",
  edit: "svg",
  feed: "svg",
  filter: "svg",
  heart: "library",
  home: "svg",
  messages: "svg",
  store: "svg",
  search: "svg",
  copy: "svg",
  comment: "svg",
  thinHeart: "svg",
  thinHeartFilled: "svg",
  listview: "svg",
  gridview: "svg",

  send: "svg",
  star: "svg",
  starHalf: "svg",
  photo: "svg",

  addCircle: "img",
  left: "img",
  right: "img",
  x: "img",
}

export default IconLoader;
