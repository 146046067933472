// import firebase from 'firebase';
import { firebase } from '@firebase/app';
import '@firebase/auth';
import '@firebase/firestore';
import '@firebase/analytics';
import '@firebase/storage';

// Your web app's Firebase configuration
// measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBpMXAMV5ztSBQGV7kESTJP7H5YFkV2vZ0",
  authDomain: "fine-design-collective.firebaseapp.com",
  projectId: "fine-design-collective",
  storageBucket: "fine-design-collective.appspot.com",
  messagingSenderId: "311370812299",
  appId: "1:311370812299:web:3aeb44c58033d5cd94bd8e",
  measurementId: "G-XG9KE1JPP6"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();

export default firebase;
