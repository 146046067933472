import React, {
  useEffect,
  useState
} from "react";
import {
  useDispatch,
  useSelector,
} from "react-redux";
import { BrowserRouter, useHistory, useLocation } from "react-router-dom";
import firebase from "./FirebaseConfig";


// this is a browser router that automatically refreshes the entire app every 6 hours
// also, whenever it gets a signal from firebase
// that I've updated the app, it refreshes
const AutoRefreshingRouter = ({ children }) => {
  const [shouldRefresh, setShouldRefresh] = useState(false);
  // const history = useHistory();
  // const location = null;

  useEffect(() => {
    const id = setTimeout(
      () => setShouldRefresh(true),
      1000 * 60 * 60 * 2 // 2 hours in milliseconds
    );
    return () => clearTimeout(id);
  }, []);

  // useEffect(() => {
  //   const id = setTimeout(
  //     () => setShouldRefresh(true),
  //     1000 * 15 // 15 secs in milliseconds
  //   );
  //   return () => clearTimeout(id);
  // }, []);

  useEffect(() => {
    // check db to see if there has been a new upload
    const startTime = Date.now();

    const unsubscribe = firebase.firestore().collection("autoRefresh")
      .onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          if (change.type === "added") {
            const docData = change.doc.data();
            const docId = change.doc.id;
            console.log("New doc added: ", docId, docData);

            if (docId === "lastUpdate") {
              if (docData.timeMs > startTime) {
                setShouldRefresh(true);
                console.log("setting refresh to true");
              }
            }

          }
          if (change.type === "modified") {
              const docData = change.doc.data();
              const docId = change.doc.id;
              console.log("doc modified: ", docId, docData);

              if (docId === "lastUpdate") {
                setShouldRefresh(true);
                console.log("setting refresh to true");
                //window.location.reload();
              }
          }
          if (change.type === "removed") {
              console.log("Removed doc: ", change.doc.data());
          }
        });
      });

    return unsubscribe;
  }, [])

  //console.log(history, location, "history and location");
  // console.log("should refresh is", shouldRefresh);
  return (
    <BrowserRouter forceRefresh={shouldRefresh}>
      {/* should refresh is {(shouldRefresh)? "true": "false"} */}
      {children}

      <Refresher shouldRefresh={shouldRefresh} />
    </BrowserRouter>
  );
}


// track url and refresh page if there is a new version of the site available
// also do anything that should be done whenever page changes
const Refresher = ({
                    shouldRefresh = false,
                  }) => {

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const pathname = location.pathname||"";

  // from redux
  const filterModal = useSelector(state => state.filterModal);
  // const filterModalUpdater = useSelector(state => state.filterModalUpdatedTimeMs);

  // states
  // keep track of last n urls
  const [previousPaths, setPreviousPaths] = useState([]);
  const [previousPathsIndex, setPreviousPathsIndex] = useState(0);
  // how many previous urls to keep track of
  const numPreviousPaths = 10;
  //const search = location.search;

  useEffect(() => {
    // do this whenever path changes
    //console.log("pathname", pathname);
    if (shouldRefresh) {
      console.log("refreshing");
      window.location.reload();
    }



    dispatch({ type: "setLocationData", value: {
      pathname: pathname,
      loweredPathname: pathname.toLowerCase()
    } });

    const tempPreviousPaths = previousPaths;
    const newPathsIndex = (previousPathsIndex + 1) % 10;
    tempPreviousPaths[newPathsIndex] = pathname;
    setPreviousPaths(tempPreviousPaths);
    setPreviousPathsIndex(newPathsIndex);

    // console.log("checking if reset needed", filterModal);
    if (filterModal.visible || filterModal.filterActive || filterModal.sortBy) {
      // if url changes and filter is active or visible, reset filter
      // do this so we're not filtering things on the new page
      dispatch({type: "resetFilterModal"});
    }



  },
  [pathname]);

  //console.log(history, location, "history and location");
  // console.log("pathname", pathname);
  return <></>;
}

export default AutoRefreshingRouter;
