import React, {
  lazy, Suspense, useEffect,
  useRef, useState
} from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useDispatch, useSelector } from 'react-redux';
import {
  Link, Route,
  // BrowserRouter as Router,
  Switch, useLocation, Redirect,
} from 'react-router-dom';
import './App.css';
import AutoRefreshingRouter from './helperComponents/AutoRefreshingRouter';
import firebase from './helperComponents/FirebaseConfig';
import IconLoader from './helperComponents/IconLoader';
import Loading from './helperComponents/Loading';
import recaptchaConfig from "./helperComponents/RecapConfig";
import CalcHighestAuthorityLevel from "./helperComponents/CalcHighestAuthorityLevel";
import { ReactComponent as SmallTitleLogoSVG } from "./images/svg/hemlock_logo_main.svg";
import {Helmet} from "react-helmet";




const Home = lazy(() => import("./pages/Home"));
const Explore = lazy(() => import("./pages/Explore"));
const Feed = lazy(() => import("./pages/Feed"));
const About = lazy(() => import("./pages/About"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Cookies = lazy(() => import("./pages/Cookies"));
const Terms = lazy(() => import("./pages/Terms"));
const Contact = lazy(() => import("./pages/Contact"));
const JoinUs = lazy(() => import("./pages/JoinUs"));
const SignIn = lazy(() => import("./pages/SignIn"));
const AddNewItem = lazy(() => import("./pages/AddNewItem"));
const ManageItems = lazy(() => import("./pages/ManageItems"));
const SellerApplication = lazy(() => import("./pages/SellerApplication"));
const ItemAndUser = lazy(() => import("./pages/ItemAndUser"));
const Post = lazy(() => import("./pages/Post"));
const EditPost = lazy(() => import("./pages/EditPost"));
const Account = lazy(() => import("./pages/Account"));
const Sales = lazy(() => import("./pages/Sales"));

const Admin = lazy(() => import("./pages/Admin"));
const AdminAllItems = lazy(() => import("./pages/adminPages/AllItems"));
const AdminAllPosts = lazy(() => import("./pages/adminPages/AllPosts"));
const AdminAllSellerApps = lazy(() => import("./pages/adminPages/AllSellerApps"));
const AdminAllUsers = lazy(() => import("./pages/adminPages/AllUsers"));
const AdminAuthorityLevels = lazy(() => import("./pages/adminPages/AuthorityLevels"));
const AdminMessages = lazy(() => import("./pages/adminPages/AdminMessages"));
const AdminAutoApprove = lazy(() => import("./pages/adminPages/AutoApprove"));
const AdminBrands = lazy(() => import("./pages/adminPages/Brands"));
const AdminCountries = lazy(() => import("./pages/adminPages/Countries"));
const AdminPauseMode = lazy(() => import("./pages/adminPages/PauseMode"));
const AdminPendingItems = lazy(() => import("./pages/adminPages/PendingItems"));
const AdminPendingPosts = lazy(() => import("./pages/adminPages/PendingPosts"));
const AdminPendingUsers = lazy(() => import("./pages/adminPages/PendingUsers"));
const AdminReports = lazy(() => import("./pages/adminPages/Reports"));
const AdminSellers = lazy(() => import("./pages/adminPages/Sellers"));
const AdminViewItem = lazy(() => import("./pages/adminPages/ViewItem"));
const AdminViewPendingItem = lazy(() => import("./pages/adminPages/ViewPendingItem"));
const AdminViewPendingPost = lazy(() => import("./pages/adminPages/ViewPendingPost"));
const AdminViewPendingUser = lazy(() => import("./pages/adminPages/ViewPendingUser"));
const AdminViewPost = lazy(() => import("./pages/adminPages/ViewPost"));
const AdminViewSellerApp = lazy(() => import("./pages/adminPages/ViewSellerApp"));
const AdminViewUser = lazy(() => import("./pages/adminPages/ViewUser"));

const Conversation = lazy(() => import("./pages/Conversation"));
const CreateReview = lazy(() => import("./pages/CreateReview"));
const AllConversations = lazy(() => import("./pages/AllConversations"));
const Notif = lazy(() => import("./pages/Notif"));
const Following = lazy(() => import("./pages/Following"));
const Reviews = lazy(() => import("./pages/Reviews"));
const PauseMessage = lazy(() => import("./pages/PauseMessage"));
const FilterModal = lazy(() => import("./helperComponents/FilterModal"));
// const Worker = lazy(() => import("./pages/Worker"));

// what page should we go to as soon as site loads?
// also what page does clicking on logo in nav bar go to?
const defaultUrl = "/explore";

const headTags = <Helmet>
  <title>Hemlock</title>
  <meta
    name="description"
    content="Hemlock"
  />
</Helmet>;

// import images

function App() {
  // const history = useHistory();

  // states
  //const [highestAuthorityLevel, setHighestAuthorityLevel] = useState("none");
  const [expanded, setExpanded] = useState(false);

  // from store
  const signedInUser = useSelector(state => state.signedInUser);
  const signedInUserPrivate = useSelector(state => state.signedInUserPrivate);
  const pendingSignedInUser = useSelector(state => state.pendingSignedInUser);
  const signedInUserLoading = useSelector(state => state.signedInUserLoading);

  const modIdDict = useSelector(state => state.modIdDict);

  const adminIdDict = useSelector(state => state.adminIdDict);

  const ownerIdDict = useSelector(state => state.ownerIdDict);

  const sellerIdDict = useSelector(state => state.sellerIdDict);
  const sellerIdsLoading = useSelector(state => state.sellerIdsLoading);

  const pauseMode = useSelector(state => state.pauseMode);
  const pauseModeLoading = useSelector(state => state.pauseModeLoading) || false;

  const loweredPathname = useSelector(state => state.loweredPathname);




  // =================================== helper funtions ================================
  const messagesClicked = () => {
    if (!signedInUserPrivate)
      return;

    firebase.firestore().collection("usersPrivate").doc(signedInUserPrivate.firebaseUid)
      .update({
        numberOfNewMessages: 0,
      })
      .then(() => {
        console.log("num new messages reset to 0");
      })
      .catch((error) => {
        console.log("error setting num new messages to 0", error);
      });

    //history.push("/Conversations");
  };


  const notifClicked = () => {
    if (!signedInUserPrivate)
      return;

    firebase.firestore().collection("usersPrivate").doc(signedInUserPrivate.firebaseUid)
      .update({
        newNotificationCount: 0,
        newNotificationCountWeb: 0,
      })
      .then(() => {
        console.log("num new notifications reset to 0");
      })
      .catch((error) => {
        console.log("error setting num new notifications to 0", error);
      });

    //history.push("/Notifications");
  };


  const bodyClick = () => {
    setExpanded(false);
  };


  // calculate highest authority level
  let highestAuthorityLevel = "none";
  //if (!modIdsLoading && !adminIdsLoading && !ownerIdsLoading && signedInUser) {
  if (signedInUser) {
    // all lists loaded.
    if (modIdDict && modIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "mod";
    }
    if (adminIdDict && adminIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "admin";
    }
    if (ownerIdDict && ownerIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "owner";
    }
  }


  // start building xml

  // button that links to page for highest level of authority that this user has
  let authorityButton = null;
  if (highestAuthorityLevel !== "none") {
    if (highestAuthorityLevel === "mod") {
      authorityButton = <Link to="/hemlock-admin"><Navbar.Text className="NavLink">Moderator</Navbar.Text></Link>;
    }
    if (highestAuthorityLevel === "admin") {
      authorityButton = <Link to="/hemlock-admin"><Navbar.Text className="NavLink">Admin</Navbar.Text></Link>;
    }
    if (highestAuthorityLevel === "owner") {
      authorityButton = <Link to="/hemlock-admin"><Navbar.Text className="NavLink">Owner</Navbar.Text></Link>;
    }
  }


  const isSeller = !!(signedInUser && signedInUser.firebaseUid
    && sellerIdDict
    && sellerIdDict[signedInUser.firebaseUid]);

  // const paypalSignupCompleted = !!(signedInUserPrivate
  //   && signedInUserPrivate.paypalSignupCompleted);




  const adminSwitchSection = [
    <Route path="/hemlock-admin/item/:itemId">
      <AdminViewItem />
    </Route>,
    <Route path="/hemlock-admin/items">
      <AdminAllItems />
    </Route>,
    <Route path="/hemlock-admin/post/:postId">
      <AdminViewPost />
    </Route>,
    <Route path="/hemlock-admin/posts">
      <AdminAllPosts />
    </Route>,

    <Route path="/hemlock-admin/pending-item/:itemId">
      <AdminViewPendingItem />
    </Route>,
    <Route path="/hemlock-admin/pending-items">
      <AdminPendingItems />
    </Route>,
    <Route path="/hemlock-admin/pending-post/:postId">
      <AdminViewPendingPost />
    </Route>,
    <Route path="/hemlock-admin/pending-posts">
      <AdminPendingPosts />
    </Route>,
    <Route path="/hemlock-admin/pending-user/:userId">
      <AdminViewPendingUser />
    </Route>,
    <Route path="/hemlock-admin/pending-users">
      <AdminPendingUsers />
    </Route>,
    <Route path="/hemlock-admin/seller-application/:sellerAppId">
      <AdminViewSellerApp />
    </Route>,
    <Route path="/hemlock-admin/seller-applications">
      <AdminAllSellerApps />
    </Route>,
    <Route path="/hemlock-admin/user/:userId">
      <AdminViewUser />
    </Route>,
    <Route path="/hemlock-admin/users">
      <AdminAllUsers />
    </Route>,

    <Route path="/hemlock-admin/sellers">
      <AdminSellers />
    </Route>,

	<Route path="/hemlock-admin/admin-messages">
      <AdminMessages />
    </Route>,

    <Route path="/hemlock-admin/authority-levels">
      <AdminAuthorityLevels />
    </Route>,
    <Route path="/hemlock-admin/auto-approve">
      <AdminAutoApprove />
    </Route>,
    <Route path="/hemlock-admin/brands">
      <AdminBrands />
    </Route>,
    <Route path="/hemlock-admin/countries">
      <AdminCountries />
    </Route>,
    <Route path="/hemlock-admin/pause-mode">
      <AdminPauseMode />
    </Route>,
    <Route path="/hemlock-admin/reports">
      <AdminReports />
    </Route>,

    <Route path="/hemlock-admin">
      <Admin />
    </Route>,

    <Route path="/fdcowner">
      <Admin />
    </Route>,
    <Route path="/fdcadmin">
      <Admin />
    </Route>,
    <Route path="/fdcmod">
      <Admin />
    </Route>,
    <Route path="/hemlockowner">
      <Admin />
    </Route>,
    <Route path="/hemlockadmin">
      <Admin />
    </Route>,
    <Route path="/hemlockmod">
      <Admin />
    </Route>,


  ];


  // A <Switch> looks through its children <Route>s and
  //     renders the first one that matches the current URL.

  let mainSwitch = <Switch>
    {/** /}<Route path="/work">
      <Worker />
    </Route>
     <Route path="/worker">
      <Worker />
    </Route>{/**/}

    <Route path="/feed">
      <Feed />
    </Route>

    <Route path="/about">
      <About />
    </Route>
    <Route path="/aboutus">
      <About />
    </Route>
    <Route path="/privacy-policy">
      <PrivacyPolicy />
    </Route>
    <Route path="/cookies">
      <Cookies />
    </Route>
    <Route path="/termsandconditions">
      <Redirect to="/terms" />
    </Route>
    <Route path="/terms">
      <Terms />
    </Route>
    <Route path="/contact">
      <Contact />
    </Route>
    <Route path="/contactus">
      <Redirect to="contact" />
    </Route>
    <Route path="/joinus">
      <JoinUs />
    </Route>
    <Route path="/invite/:inviteId">
      <JoinUs usingInvite={true} />
    </Route>
    <Route path="/signin">
      <SignIn />
    </Route>

    <Route path="/account">
      <Account />
    </Route>
    {/*<Route path="/cart">
      <Cart />
    </Route>
    <Route path="/purchases">
      <Purchases />
    </Route>*/}

    <Route path="/sales">
      <Sales />
    </Route>

    <Route path="/becomeaseller">
      {
        // check if current user is a seller
        // if not, show seller register page
        // if they are a seller
        // show paypal signup section
        // (isSeller)
        //   ? <PaypalSignup />
        //   : <SellerApplication />
        (isSeller)
          ? <h3 className="textAlignCenter">Sign Up Complete</h3>
          : <SellerApplication />
      }
    </Route>

    <Route path="/manageitems/allitems">
      {
        // check if current user is a seller
        // if not, show seller register page
        // if they are a seller but haven't completed their paypal signup,
        // show paypal signup section
        // otherwise show manage items
        // (isSeller || sellerIdsLoading)
        //   ? (paypalSignupCompleted || !signedInUserPrivate)
        //     ? <ManageItems page={"allItems"} />
        //     : <PaypalSignup />
        //   : <SellerApplication />
        (isSeller || sellerIdsLoading)
          ? <ManageItems page={"allItems"} />
          : <SellerApplication />
      }
    </Route>
    <Route path="/manageitems/recentlysold">
      {
        // check if current user is a seller
        // if not, show seller register page
        // if they are a seller but haven't completed their paypal signup,
        // show paypal signup section
        // otherwise show manage items
        // (isSeller || sellerIdsLoading)
        //   ? (paypalSignupCompleted || !signedInUserPrivate)
        //     ? <ManageItems page={"recentlySold"} />
        //     : <PaypalSignup />
        //   : <SellerApplication />
        (isSeller || sellerIdsLoading)
          ? <ManageItems page={"recentlySold"} />
          : <SellerApplication />
      }
    </Route>

    <Route exact path="/manageitems/:itemId">
      <AddNewItem editExisting={true} />
    </Route>

    <Route path="/manageitems">
      {
        // check if current user is a seller
        // if not, show seller register page
        // if they are a seller but haven't completed their paypal signup,
        // show paypal signup section
        // otherwise show manage items
        // (isSeller || sellerIdsLoading)
        //   ? (paypalSignupCompleted || !signedInUserPrivate)
        //     ? <ManageItems />
        //     : <PaypalSignup />
        //   : <SellerApplication />
        (isSeller || sellerIdsLoading)
          ? <ManageItems />
          : <SellerApplication />
      }
    </Route>
    <Route path="/addnewitem">
      <AddNewItem />
    </Route>

    <Route path="/item/:itemCat/:itemBrand/:itemNameInUrl">
      <ItemAndUser page="thisItem" />
    </Route>
    <Route path="/item/:itemId">
      <ItemAndUser page="thisItem" />
    </Route>

    <Route path="/user/:userId/reviews/as-seller">
      <Reviews page="as-seller" />
    </Route>
    <Route path="/user/:userId/reviews/as-buyer">
      <Reviews page="as-buyer" />
    </Route>
    <Route path="/user/:userId/reviews">
      <Reviews page="as-seller" />
    </Route>

    <Route path="/user/:userId/store">
      <ItemAndUser page="userStore" />
    </Route>
    <Route path="/user/:userId/feed">
      <ItemAndUser page="userFeed" />
    </Route>
    <Route path="/user/:userId/following">
      <Following page="following" />
    </Route>
    <Route path="/user/:userId/followers">
      <Following page="followers" />
    </Route>
    <Route path="/user/:userId">
      <ItemAndUser page="userFeed" />
    </Route>

    <Route path="/post/:username/:id">
      <Post />
    </Route>
    <Route path="/post/:id">
      <Post />
    </Route>
    <Route path="/editpost/:username/:id">
      <EditPost />
    </Route>
    <Route path="/editpost/:id">
      <EditPost />
    </Route>


    {adminSwitchSection}


    <Route path="/conversation/item/:convoId/review">
      <CreateReview  type="itemConvo" />
    </Route>
    <Route path="/conversations/item/:convoId/review">
      <CreateReview  type="itemConvo"/>
    </Route>
    <Route path="/conversation/items/:convoId/review">
      <CreateReview  type="itemConvo"/>
    </Route>
    <Route path="/conversations/items/:convoId/review">
      <CreateReview  type="itemConvo"/>
    </Route>

    <Route path="/conversation/item/:convoId">
      <Conversation hasItem={true} page="convo"/>
    </Route>
    <Route path="/conversations/item/:convoId">
      <Conversation hasItem={true} page="convo"/>
    </Route>
    <Route path="/conversation/items/:convoId">
      <Conversation hasItem={true} page="convo"/>
    </Route>
    <Route path="/conversations/items/:convoId">
      <Conversation hasItem={true} page="convo"/>
    </Route>
    <Route path="/conversations/items">
      <AllConversations page={"items"} />
    </Route>
    {/*}<Route path="/conversations/requests">
      <AllConversations page={"requests"} />
    </Route>*/}
    <Route path="/conversations/:convoId">
      <Conversation />
    </Route>
    <Route path="/conversation/:convoId">
      <Conversation />
    </Route>
    {/*}<Route path="/requests/:convoId">
      <Conversation isRequest={true} />
    </Route>*/}
    <Route path="/request/:convoId">
      <Conversation isRequest={true} />
    </Route>
    <Route path="/conversations">
      <AllConversations page={"conversations"} />
    </Route>

    <Route path="/notifications">
      <Notif />
    </Route>

    <Route path="/explore/search/items">
      <Explore page="searchitems"/>
    </Route>
    <Route path="/explore/search/users">
      <Explore page="searchusers"/>
    </Route>
    <Route path="/explore/search/posts">
      <Explore page="searchposts"/>
    </Route>
    <Route path="/explore/search">
      <Explore page="searchitems"/>
    </Route>
    <Route path="/explore/items">
      <Explore page="exploreitems"/>
    </Route>
    <Route path="/explore/posts">
      <Explore page="exploreposts"/>
    </Route>
    <Route path="/explore">
      <Explore page="exploreitems"/>
    </Route>

    <Route path="/following">
      <Following page={"following"} />
    </Route>

    <Route path="/followers">
      <Following page={"followers"} />
    </Route>

    <Route path="/Items">
      {/* all items page */}
      <Home />
    </Route>

    <Route path="/">
      <Redirect to={defaultUrl} />
    </Route>
  </Switch>;


 if (pauseModeLoading && highestAuthorityLevel === "none") {
 // if ((pauseModeLoading || true) && highestAuthorityLevel === "none") {
  // if (true) {
    mainSwitch = <Switch>
      {adminSwitchSection}

      <Route path="/">
        <Loading />
      </Route>
    </Switch>;
  }


  if (pauseMode.paused && highestAuthorityLevel === "none") {
    mainSwitch = <Switch>
      {adminSwitchSection}

      <Route path="/">
        <PauseMessage />
      </Route>
    </Switch>;
  }

  let newMsgCount = 0;
  if (signedInUserPrivate && typeof signedInUserPrivate.numberOfNewMessages === "number"
    && signedInUserPrivate.numberOfNewMessages > 0) {
    newMsgCount = signedInUserPrivate.numberOfNewMessages;
  }
  // let cartCount = 0;
  // if (signedInUserPrivate && signedInUserPrivate.cartRefsDict) {
  //   cartCount = Object.keys(signedInUserPrivate.cartRefsDict).length;
  // }
  let notifCount = 0;
  if (signedInUserPrivate && typeof signedInUserPrivate.newNotificationCount === "number"
    && signedInUserPrivate.newNotificationCount > 0) {
    notifCount = signedInUserPrivate.newNotificationCount;
  }


  // console.log("lowered path name:", loweredPathname);
              // don't show nav bar in pause mode        // always show nav bar when user is admin
  const navbarClass = ((!pauseMode.paused && !pauseModeLoading) || highestAuthorityLevel !== "none"
                          // don't show top nav bar on these pages
                           && !loweredPathname.includes("signin")
                           && !loweredPathname.includes("joinus"))
    ? "mainNavBar"
    // if no user, don't show nav bar
    // also if pause mode and not admin, don't show nav bar
    : "mainNavBar displayNone";

  // console.log("render on app.js");


  let messagesIcon = null;
  let notifIcon = null;

  if (signedInUser) {
    messagesIcon = <div className="iconContainer"
      onClick={messagesClicked}>
      <Link to="/conversations">
        {
          (loweredPathname.substring(0, 14) === "/conversations")
          ? <IconLoader name="messages" active={true} />
          : <IconLoader name="messages" active={false} />
        }
        {
          (newMsgCount > 0)
            ? <>
              <div className="iconCounter">
                {
                  (newMsgCount > 99)
                    ? "9+"
                    : newMsgCount
                }
              </div>
            </>
            : null
        }
      </Link>
    </div>;


    notifIcon = <div className="iconContainer"
      onClick={notifClicked}>

      <Link to="/notifications">
        {
          (loweredPathname.substring(0, 14) === "/notifications")
          ? <IconLoader name="bell" active={true}/>
          : <IconLoader name="bell" active={false}/>
        }
        {
          (notifCount > 0)
            ? <>
                <div className="iconCounter">
                  {
                    (notifCount > 99)
                      ? "9+"
                      : notifCount
                  }
                </div>
              </>
            : null
        }
      </Link>
    </div>;
  }

  const navbarLogo = <Link to={defaultUrl}>
    <Navbar.Brand className="mx-auto">
      <SmallTitleLogoSVG className="FDLogoSmall smallTitleLogo" alt="logo" />
    </Navbar.Brand>
  </Link>;

  const navbarIcons  = <div className="navbarIconContainer">
    {messagesIcon}
    {notifIcon}
  </div>;

  let signedInNavActions = null;
  let notSignedInNavActions = null;

  if (signedInUser && !pendingSignedInUser){
      signedInNavActions =
		<>
			<Link to="/becomeaseller"><Navbar.Text className="NavLink">Become A Seller</Navbar.Text></Link>
			<Link to="/account"><Navbar.Text className="NavLink">My Account</Navbar.Text></Link>
    </>;
  }
  else if (!signedInUser && !pendingSignedInUser) {
    notSignedInNavActions = <>
			<Link to="/signin"><Navbar.Text className="NavLink">Sign In</Navbar.Text></Link>
    </>;
  }

  const navLinks = <Navbar.Collapse id="basic-navbar-nav">
    <Nav className="ml-auto">

      {// if there is a signed in user, add Purchases button
        // (signedInUser)
        //   ? <Link to="/purchases"><Navbar.Text className="NavLink">Purchases</Navbar.Text></Link>
        //   : null
      }

      <Link to="/about">
        <Navbar.Text className="NavLink">About</Navbar.Text>
      </Link>
      <Link to="/contact">
        <Navbar.Text className="NavLink">Contact Us</Navbar.Text>
      </Link>
      {notSignedInNavActions}
      {signedInNavActions}
      {// if there is a signed in user and that user is not a pending user,
        //  add signout button

      }
      {// if user is mod/admin/owner, add mod/admin/owner button
        authorityButton
      }
    </Nav>
  </Navbar.Collapse>;

  return (
    <div className="backgroundImage" style={{
      //backgroundImage: "url("+ background +")",
      paddingBottom: "1px",
    }}>
      {headTags}
      <AutoRefreshingRouter>
        <ErrorBoundary>
          <Suspense fallback={<div className="loadingPage">
            <Navbar variant="dark" expand="lg" className={navbarClass}>
              <Link to={defaultUrl}>
                <Navbar.Brand>
                  <SmallTitleLogoSVG className="FDLogoSmall smallTitleLogo" alt="logo" />
                </Navbar.Brand>
              </Link>
            </Navbar>

            {
              // <div className="loading">Loading...</div>
            }
            <Loading/>

            <BottomNavBar />
          </div>}>
            <div onClick={bodyClick}>
              {/* This is the header/navigation bar */}
              <Navbar variant="dark" expand="lg" className={navbarClass} expanded={expanded}>

                <div className="navHideOnMobile navbarContainer navbarContainerDesktop">
                  {/* show on desktop */}
                  {navbarLogo}
                  {navbarIcons}
                  {navLinks}
                </div>
                <div className="navShowOnMobile navbarContainer navbarContainerMobile">
                  {/* show on mobile */}

                  <Navbar.Toggle aria-controls="basic-navbar-nav"
                    onClick={(e) => {
                      e.stopPropagation();
                      setExpanded(expanded ? false : "expanded");
                    }} />

                  {navbarLogo}
                  {navbarIcons}
                </div>
                <div className="navShowOnMobile" style={{flexBasis: "100%"}}>
                  {navLinks}
                </div>


              </Navbar>

              {/* This component is responsible for tracking the signed in user.
                If there is a signed in user, this will update the redux db  */}
              <UserSigninTracker />

              {mainSwitch}

              {/* This component creates the black bar at the bottom of most pages */}
              <BottomNavBar />


              {/* This component creates Modals */}
              <ModalManager />
              {/* This component creates alerts */}
              <AlertManager />
              {/* This compenent loads inportant data into redux db */}
              <DataLoader />
              {/* This component loads scripts programatically. Currently being used to load recaptcha */}
              <ScriptLoader />
              {/* This component calculates this user's highest authorityLevel based on
                  mod, admin, and owner id lists and saves the result in redux store */}
              <CalcHighestAuthorityLevel />
              {/* show filter modal on any page that needs it
                (home, explore, user store, manage items) */}
              <FilterModal/>
            </div>
          </Suspense>
        </ErrorBoundary>
      </AutoRefreshingRouter>
    </div>
  );
}


function BottomNavBar() {
  const signedInUser = useSelector(state => state.signedInUser);
  const sellerIdDict = useSelector(state => state.sellerIdDict);

  const pauseMode = useSelector(state => state.pauseMode);
  const pauseModeLoading = useSelector(state => state.pauseModeLoading) || false;
  const modIdDict = useSelector(state => state.modIdDict);
  const adminIdDict = useSelector(state => state.adminIdDict);
  const ownerIdDict = useSelector(state => state.ownerIdDict);




  // calculate highest authority level
  let highestAuthorityLevel = "none";
  //if (!modIdsLoading && !adminIdsLoading && !ownerIdsLoading && signedInUser) {
  if (signedInUser) {
    // all lists loaded.
    if (modIdDict && modIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "mod";
    }
    if (adminIdDict && adminIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "admin";
    }
    if (ownerIdDict && ownerIdDict[signedInUser.firebaseUid]) {
      // this user has access to this list
      // this user is on this list
      // this user is a mod
      highestAuthorityLevel = "owner";
    }
  }

  const location = useLocation();
  const loweredPathname = (location.pathname || "").toLowerCase();
  //const search = location.search;

  // useEffect(() => {
  //   // do this whenever path changes
  //   //console.log("navbar pathname", loweredPathname);
  // },
  // [loweredPathname]);

  let homeIcon = <IconLoader name="home" active={false} />;
  if (loweredPathname === "/items") {
    homeIcon =  <IconLoader name="home" active={true} />;
  }

  let exploreIcon = <IconLoader name="search" active={false} />;
  if (loweredPathname.includes("explore")) {
    exploreIcon = <IconLoader name="search" active={true} />;
  }

  let feedIcon = <IconLoader name="feed" active={false} />;
  if (loweredPathname.substring(0, 5) === "/feed") {
    feedIcon = <IconLoader name="feed" active={true} />;;
  }

  // let storeIcon = greyStoreIcon;
  // if (loweredPathname.includes("manageitems")) {
  //   storeIcon = whiteStoreIcon;
  // }


  let newItemIcon = <IconLoader name="add" active={false} />;
  if (loweredPathname.includes("addnewitem")) {
    newItemIcon = <IconLoader name="add" active={true} />;
  }


  const defaultProfilePicture = <div className="profilePictureBackground">
    <div className="profilePictureInnerBackground">
      <SmallTitleLogoSVG className="profilePicture defaultProfilePicture" alt="profile"/>
    </div>
  </div>;

  let profileButton = defaultProfilePicture;

  if (signedInUser && signedInUser.username) {
    const usernameForUrl = (signedInUser.username || "").toLowerCase();
    if (signedInUser.profilePictureUrl) {
      let proPicUrl = signedInUser.profilePictureUrl;

      if (signedInUser.resizedProfilePictureUrls && signedInUser.resizedProfilePictureUrls.smallurl) {
        proPicUrl = signedInUser.resizedProfilePictureUrls.smallurl;
      }

      profileButton = <Link className="navButton" to={"/user/" + usernameForUrl}>
        <div className="profilePictureBackground">
          <img src={proPicUrl} className="profilePicture" alt="profile" />
        </div>
      </Link>;
    }
    else {
      profileButton = <Link to={"/user/" + usernameForUrl}>
        {defaultProfilePicture}
      </Link>;
    }
  }




  const rightButtons = [];


  const isSeller = !!(signedInUser && signedInUser.firebaseUid
    && sellerIdDict
    && sellerIdDict[signedInUser.firebaseUid]);
  if (isSeller) {
    // this user is a seller, show add new item and manage items buttons

    rightButtons.push(
      <Link className="navButton" to="/addnewitem">{newItemIcon}</Link>,
      //<Link to="/manageitems"><img src={storeIcon} className="icon" alt="store"/></Link>,
      profileButton,
    );
  }
  else {
    // this user is not a seller, show account button
    if (signedInUser) {
      rightButtons.push (
        profileButton
      );
    }
  }


    // don't show nav bar in pause mode        // always show nav bar when user is admin
  if (((!pauseModeLoading && !pauseMode.paused) || highestAuthorityLevel !== "none")
      // don't show bottom nav bar on these pages
       && !loweredPathname.includes("signin")
       && !loweredPathname.includes("joinus")
    )
    return <>
      <div className="bottomNavBar">
        <Link className="navButton" to="/items">{homeIcon}</Link>
        <Link className="navButton" to="/explore">{exploreIcon}</Link>
        <Link className="navButton" to="/feed">{feedIcon}</Link>
        {rightButtons}
      </div>
    </>;
  else
    return <></>;
}

function UserSigninTracker() {
  // this component tracks when a user signs in and updates the redux db

  const dispatch = useDispatch();
  const unsubscribeRef = useRef(null);
  const privateUnsubscribeRef = useRef(null);
  const unsubscribePendingUserRef = useRef(null);
  const userDataLoaded = useRef(false);
  const privateUserDataLoaded = useRef(false);

  // effects
  useEffect(() => {
    const authUnsubscribe = firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        // User is signed in.
        // var displayName = user.displayName;
        // const email = user.email;
        // var emailVerified = user.emailVerified;
        // var photoURL = user.photoURL;
        // var isAnonymous = user.isAnonymous;
        const uid = user.uid;
        // var providerData = user.providerData;

        dispatch({ type: 'setSignedInUserLoading', value: true });

        dispatch({
          type: 'setSignedInUser', value: {
            email: user.email,
            uid: user.uid,
          }
        });

        // if there is already a listener active that listens to changes
        // in the current user, unsubscribe
        if (unsubscribeRef.current !== null)
          unsubscribeRef.current();

        const doc = firebase.firestore().collection('users').doc(uid);

        if (!doc) {
          console.log("signed in user doc not found 1");
          return dispatch({ type: 'setSignedInUserLoading', value: false });
        }

        unsubscribeRef.current = doc.onSnapshot(docSnapshot => {
          if (!docSnapshot.data()) {

            console.log("signed in user doc not found 2");
            dispatch({ type: 'setSignedInUserLoading', value: false });

            if (unsubscribePendingUserRef.current)
              unsubscribePendingUserRef.current();

            unsubscribePendingUserRef.current = firebase.firestore().collection("pendingUsers")
              .doc(uid)
              .onSnapshot(pendingDocSnap => {
                if (!pendingDocSnap.data()) {
                  return console.log("this is not a pending user");
                }

                dispatch({
                  type: 'setPendingSignedInUser', value: {
                    ...pendingDocSnap.data(),
                    email: user.email,
                    uid: user.uid,
                  }
                });
              });

            return;
          }

          //console.log(`Received doc snapshot: ${Object.keys(docSnapshot.data())}`);
          // ...
          dispatch({
            type: 'setSignedInUser', value: {
              ...docSnapshot.data(),
              email: user.email,
              uid: user.uid,
            }
          });

          dispatch({ type: 'setPendingSignedInUser', value: null });

          userDataLoaded.current = true;
          if (privateUserDataLoaded.current) {
            // both normal and private user data loaded
            // set loading to false
            dispatch({ type: "setSignedInUserLoading", value: false });
          }
        }, err => {
          console.log(`Encountered error: ${err}`);
          userDataLoaded.current = true;
          if (privateUserDataLoaded.current) {
            // both normal and private user data loaded
            // set loading to false
          }
          dispatch({ type: "setSignedInUserLoading", value: false });

        });

        // repeat the previous process but for the user's private data
        if (privateUnsubscribeRef.current !== null)
          privateUnsubscribeRef.current();

        const docPrivate = firebase.firestore().collection('usersPrivate').doc(uid);

        if (!docPrivate)
          return dispatch({ type: 'setSignedInUserLoading', value: false });

        privateUnsubscribeRef.current = docPrivate.onSnapshot(docSnapshot => {

          if (!docSnapshot.data())
            return dispatch({ type: 'setSignedInUserLoading', value: false });

          //console.log(`Received private doc snapshot: ${Object.keys(docSnapshot.data())}`);
          // ...
          dispatch({
            type: 'setSignedInUserPrivate', value: {
              ...docSnapshot.data(),
              email: user.email,
              uid: user.uid,
            }
          });

          privateUserDataLoaded.current = true;
          if (userDataLoaded.current) {
            // both normal and private user data loaded
            // set loading to false
            dispatch({ type: "setSignedInUserLoading", value: false });
          }
        }, err => {
          console.log(`Encountered error: ${err}`);
          privateUserDataLoaded.current = true;
          if (userDataLoaded.current) {
            // both normal and private user data loaded
            // set loading to false
          }

          dispatch({ type: "setSignedInUserLoading", value: false });
        });

      } else {
        // User is signed out.
        dispatch({ type: "setSignedInUser", value: null });
        dispatch({ type: "setPendingSignedInUser", value: null });
        dispatch({ type: 'setSignedInUserPrivate', value: null });
        dispatch({ type: 'setSignedInUserLoading', value: false });

        if (unsubscribeRef.current !== null) {
          unsubscribeRef.current();
          unsubscribeRef.current = null;
        }

        if (privateUnsubscribeRef.current !== null) {
          privateUnsubscribeRef.current();
          privateUnsubscribeRef.current = null;
        }

        if (unsubscribePendingUserRef.current !== null) {
          unsubscribePendingUserRef.current();
          unsubscribePendingUserRef.current = null;
        }

        // clear local storage
        window.localStorage.setItem("pendingNewItem", null);
      }
    });

    return () => {
      // clean up
      // unsubscribe from subscriptions
      authUnsubscribe();
      if (unsubscribeRef.current !== null) {
        unsubscribeRef.current();
      }

      if (privateUnsubscribeRef.current !== null) {
        privateUnsubscribeRef.current();
      }

      if (unsubscribePendingUserRef.current !== null) {
        unsubscribePendingUserRef.current();
      }
    };
  }, []);

  return <></>;
}

function AlertManager() {
  const dispatch = useDispatch();

  // from redux
  const alertData = useSelector(state => ({
    text: state.alertText,
    variant: state.alertVariant,
    visible: state.alertVisible,
    delay: state.alertDelay,
  }));

  // effects
  useEffect(() => {
    // this function runs whenever alertData.visible changes
    if (!alertData.visible)
      // no alert active, do nothing
      return;

    // an alert is visible, wait for delay, then clear alert
    setTimeout(() => {
      dispatch({
        type: 'setAlertWithDelayAndVisible',
        value: {
          text: "",
          variant: "dark",
          delay: 0,
          visible: false,
        }
      });
    }, alertData.delay);
  }, [alertData.visible, alertData.delay]);


  // xml builders
  let alertXml = null;
  if (alertData.visible) {
    alertXml = <Alert variant={alertData.variant} className="alert">
      {alertData.text}
    </Alert>;
  }

  return alertXml;
}

function ModalManager() {
  const dispatch = useDispatch();

  // from redux
  const modalData = useSelector(state => ({
    titleText: state.modalTitleText,
    bodyText: state.modalBodyText,
    bodyElement: state.modalBodyElement,
    showing: state.modalShowing,
    useButtons: state.modalUseButtons,
    confirmCallback: state.modalConfirmCallback,
    cancelCallback: state.modalCancelCallback,
    confirmText: state.modalConfirmText,
    cancelText: state.modalCancelText,
  }));


  // states
  //const [showing, setShowing] = useState(true);

  // helper functions
  const handleClose = () => dispatch({
    type: "setModal",
    value: {
      showing: false,
    }
  });

  let body = <p>{modalData.bodyText}</p>;
  if (modalData.bodyElement) {
    body = modalData.bodyElement;
  }

  let footerClass = "";
  if (!modalData.useButtons) {
    footerClass += " displayNone ";
  }

  // xmls
  return <Modal show={modalData.showing} onHide={handleClose}>
    <Modal.Header closeButton>
      <Modal.Title>{modalData.titleText}</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      {body}
    </Modal.Body>

    <Modal.Footer className={footerClass}>
      <Button variant="secondary" onClick={handleClose}>{modalData.cancelText}</Button>
      <Button variant="primary" onClick={() => {
        if (modalData.confirmCallback) {
          modalData.confirmCallback();
        }

        handleClose();
      }}>
        {modalData.confirmText}
      </Button>
    </Modal.Footer>
  </Modal>;
}


function DataLoader() {
  // this component that loads some data into redux db

  const dispatch = useDispatch();

  const unsubscribeBrandsRef = useRef(null);
  const unsubscribeItemPreapprovedUserIdsRef = useRef(null);
  const unsubscribePostPreapprovedUserIdsRef = useRef(null);
  const unsubscribeUsernameToFirebaseUidRef = useRef(null);
  const unsubscribeSellerIdRef = useRef(null);
  const unsubscribeMiscDataRef = useRef(null);
  const unsubscribeCountriesRef = useRef(null);

  const unsubscribeOwnerIdsRef = useRef(null);
  const unsubscribeAdminIdsRef = useRef(null);
  const unsubscribeModIdsRef = useRef(null);


  const signedInUser = useSelector(state => state.signedInUser);

  // effects
  useEffect(() => {

    // if something goes wrong, always set loading to false after a while
    setTimeout(() => {
      // set timeout pause mode loading false after 5 seconds
      dispatch({
        type: "setPauseModeLoading",
        value: false,
      });
    }, 5000);



    const db = firebase.firestore();

    if (unsubscribeBrandsRef.current) {
      unsubscribeBrandsRef.current();
    }
    if (unsubscribeItemPreapprovedUserIdsRef.current) {
      unsubscribeItemPreapprovedUserIdsRef.current();
    }
    if (unsubscribePostPreapprovedUserIdsRef.current) {
      unsubscribePostPreapprovedUserIdsRef.current();
    }
    if (unsubscribeUsernameToFirebaseUidRef.current) {
      unsubscribeUsernameToFirebaseUidRef.current();
    }
    if (unsubscribeSellerIdRef.current) {
      unsubscribeSellerIdRef.current();
    }
    if (unsubscribeMiscDataRef.current) {
      unsubscribeMiscDataRef.current();
    }
    if (unsubscribeCountriesRef.current) {
      unsubscribeCountriesRef.current();
    }


    // load brands
    unsubscribeBrandsRef.current = db.collection("brands")
      .onSnapshot(
        (querySnapshot) => {
          const brandList = [];
          querySnapshot.forEach((doc) => {
            brandList.push(doc.data());
          });
          dispatch({
            type: "setBrandList",
            value: brandList
          });
        },
        (error) => {
          console.log("error getting brands", error);
        }
      );

    // load itemPreapprovedUserId
    unsubscribeItemPreapprovedUserIdsRef.current = db.collection("itemPreapprovedUserIds")
      .onSnapshot(
        (querySnapshot) => {
          const itemPreapprovedUserIdDict = {};
          querySnapshot.forEach((doc) => {
            itemPreapprovedUserIdDict[doc.id] = doc.data();
          });
          dispatch({
            type: "setItemPreapprovedUserIdDict",
            value: itemPreapprovedUserIdDict
          });
        },
        (error) => {
          console.log("error getting itemPreapprovedUser ids", error);
          dispatch({
            type: "setItemPreapprovedUserIdsLoading",
            value: false
          });
        }
      );

    // load postPreapprovedUserId
    unsubscribePostPreapprovedUserIdsRef.current = db.collection("postPreapprovedUserIds")
      .onSnapshot(
        (querySnapshot) => {
          const postPreapprovedUserIdDict = {};
          querySnapshot.forEach((doc) => {
            postPreapprovedUserIdDict[doc.id] = doc.data();
          });
          dispatch({
            type: "setPostPreapprovedUserIdDict",
            value: postPreapprovedUserIdDict
          });
        },
        (error) => {
          console.log("error getting postPreapprovedUser ids", error);
          dispatch({
            type: "setPostPreapprovedUserIdsLoading",
            value: false
          });
        }
      );

    // load usernameToFirebaseUid
    unsubscribeUsernameToFirebaseUidRef.current = db.collection("usernameToFirebaseUid")
      .onSnapshot(
        (querySnapshot) => {
          const usernameToFirebaseUid = {};
          const firebaseUidToUsername = {};

          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            usernameToFirebaseUid[doc.id] = docData;
            firebaseUidToUsername[docData.firebaseUid] = docData;
            // firebaseUidToUsername[docData.firebaseUid + " 2"] = docData;
          });

          // console.log("firebaseUid to username", Object.keys(firebaseUidToUsername).length, firebaseUidToUsername);

          dispatch({
            type: "setUsernameToFirebaseUid",
            value: usernameToFirebaseUid
          });
          dispatch({
            type: "setFirebaseUidToUsername",
            value: firebaseUidToUsername
          });
        },
        (error) => {
          console.log("error getting usernameToFirebaseUid", error);
        }
      );


    // load sellerIds
    unsubscribeSellerIdRef.current = db.collection("sellerIds")
      .onSnapshot(
        (querySnapshot) => {
          const sellerIdDict = {};

          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            sellerIdDict[doc.id] = docData;
          });

          dispatch({
            type: "setSellerIdDict",
            value: sellerIdDict
          });
        },
        (error) => {
          console.log("error getting seller ids", error);

          dispatch({ type: "setSellerIdsLoading", value: false });
        }
      );

    // load Countries
    unsubscribeCountriesRef.current = db.collection("countries")
      .where("hidden", "==", false)
      .onSnapshot(
        (querySnapshot) => {
          const countriesDict = {};

          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            countriesDict[doc.id] = docData;
          });

          dispatch({
            type: "setCountriesDict",
            value: countriesDict
          });
        },
        (error) => {
          console.log("error getting countries", error);

          dispatch({ type: "setCountriesLoading", value: false });
        }
      );


    // load miscellaneous data
    unsubscribeMiscDataRef.current = db.collection("miscData")
      .onSnapshot(
        (querySnapshot) => {
          const tempDataDict = {};

          querySnapshot.forEach((doc) => {
            const docData = doc.data();
            tempDataDict[doc.id] = docData;
          });
          if (tempDataDict.numItems)
            dispatch({
              type: "setNumItems",
              value: tempDataDict.numItems.numItems
            });

          if (tempDataDict.currencyConversion && tempDataDict.currencyConversion.rates)
            dispatch({
              type: "setCurrencyConversionRates",
              value: tempDataDict.currencyConversion.rates
            });

          if (tempDataDict.pauseMode)
            dispatch({
              type: "setPauseMode",
              value: {...tempDataDict.pauseMode,
                // paused: true, // testing
              }
            });

          if (tempDataDict.autoApprove)
            dispatch({
              type: "setAutoApprove",
              value: tempDataDict.autoApprove,
            });

          // pause mode loading false
          dispatch({
            type: "setPauseModeLoading",
            value: false,
          });

        },
        (error) => {
          console.log("error getting miscellaneous site data", error);
          // pause loading false
          dispatch({
            type: "setPauseModeLoading",
            value: false,
          });
        }
      );


    return () => {
      // clean up subscriptions

      if (unsubscribeBrandsRef.current) {
        unsubscribeBrandsRef.current();
      }
      if (unsubscribeItemPreapprovedUserIdsRef.current) {
        unsubscribeItemPreapprovedUserIdsRef.current();
      }
      if (unsubscribePostPreapprovedUserIdsRef.current) {
        unsubscribePostPreapprovedUserIdsRef.current();
      }
      if (unsubscribeUsernameToFirebaseUidRef.current) {
        unsubscribeUsernameToFirebaseUidRef.current();
      }
      if (unsubscribeSellerIdRef.current) {
        unsubscribeSellerIdRef.current();
      }
      if (unsubscribeMiscDataRef.current) {
        unsubscribeMiscDataRef.current();
      }
    };
  }, []);

  useEffect(() => {
    const db = firebase.firestore();

    if (signedInUser) {
      // you must be signed in to access these

      if (unsubscribeOwnerIdsRef.current) {
        unsubscribeOwnerIdsRef.current();
      }
      if (unsubscribeAdminIdsRef.current) {
        unsubscribeAdminIdsRef.current();
      }
      if (unsubscribeModIdsRef.current) {
        unsubscribeModIdsRef.current();
      }

      // load ownerIds
      unsubscribeOwnerIdsRef.current = db.collection("ownerIds")
        .onSnapshot(
          (querySnapshot) => {
            const ownerIdDict = {};
            querySnapshot.forEach((doc) => {
              ownerIdDict[doc.id] = doc.data();
            });
            dispatch({
              type: "setOwnerIdDict",
              value: ownerIdDict
            });
          },
          (error) => {
            console.log("error getting owner ids", error);
            dispatch({
              type: "setOwnerIdsLoading",
              value: false
            });
          }
        );

      // load adminIds
      unsubscribeAdminIdsRef.current = db.collection("adminIds")
        .onSnapshot(
          (querySnapshot) => {
            const adminIdDict = {};
            querySnapshot.forEach((doc) => {
              adminIdDict[doc.id] = doc.data();
            });
            dispatch({
              type: "setAdminIdDict",
              value: adminIdDict
            });
          },
          (error) => {
            console.log("error getting admin ids", error);
            dispatch({
              type: "setAdminIdsLoading",
              value: false
            });
          }
        );

      // load modIds
      unsubscribeModIdsRef.current = db.collection("modIds")
        .onSnapshot(
          (querySnapshot) => {
            const modIdDict = {};
            querySnapshot.forEach((doc) => {
              modIdDict[doc.id] = doc.data();
            });
            dispatch({
              type: "setModIdDict",
              value: modIdDict
            });
          },
          (error) => {
            console.log("error getting mod ids", error);
            dispatch({
              type: "setModIdsLoading",
              value: false
            });
          }
        );
    }

    return () => {
      // clean up
      // unsubscribe from subscriptions
      if (unsubscribeOwnerIdsRef.current) {
        unsubscribeOwnerIdsRef.current();
      }
      if (unsubscribeAdminIdsRef.current) {
        unsubscribeAdminIdsRef.current();
      }
      if (unsubscribeModIdsRef.current) {
        unsubscribeModIdsRef.current();
      }
    };

  }, [signedInUser]);

  return <></>;
}

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, errorInfo);
    console.log("Error:", error, errorInfo);
  }


  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      // TODO make home link window.location.href = "/";

      const homeClicked = () => {
        // go home and reload app
        console.log("going home");
        window.location.href = defaultUrl;
      }

      return <div className="errorPage">
        <Navbar variant="dark" expand="lg" className="mainNavBar">
          <Navbar.Brand>
            <SmallTitleLogoSVG className="FDLogoSmall smallTitleLogo" alt="logo"
              onClick={homeClicked} />
          </Navbar.Brand>
        </Navbar>

        <h3 className="textAlignCenter">Something went wrong</h3>
        <p className="textAlignCenter">Reload to try again</p>
        <p onClick={homeClicked} className="whiteTextLink textAlignCenter">Or click here to go Home</p>
        <BottomNavBar />
      </div>;
    }

    return this.props.children;
  }
}

function ScriptLoader () {
  // this component loads scripts programatically
  // using it to load ReCAPTCHA

  const dispatch = useDispatch();
  // ==================================== Effects =================================
  useEffect(() => {
    // load ReCAPTCHA script
    // only do this once per site load and do it as soon as site loads
    const recapScript = document.createElement("script");
    recapScript.src = "https://www.google.com/recaptcha/api.js?render=" + recaptchaConfig.siteKey;
    recapScript.addEventListener("load", () => {
      // let other pages know that ReCAPTCHA script is loaded and ready to be used
      dispatch({
        type: "setRecaptchaLoaded",
        value: true,
      })
    });
    document.body.appendChild(recapScript);
  }, []);

  return <></>;
};


export default App;
